export default function useCurrentTheme() {
  type Theme = "light" | "dark";

  const colorMode = useColorMode();

  const currentTheme = computed<Theme>(() => {
    if (typeof window === "undefined") {
      // Default to 'light' theme on the server
      return "light";
    }

    if (colorMode.preference === "system") {
      return colorMode.value as Theme;
    }

    return colorMode.preference as Theme;
  });

  const toggleTheme = () => {
    if (currentTheme.value === "dark") {
      colorMode.preference = "light";
    } else {
      colorMode.preference = "dark";
    }
  };

  return { currentTheme, toggleTheme };
}
